import React from "react";

export const Footer = () => {
  return (
    <footer className="footer text-center">
      <div className="container">
        <span className="text-muted">ghaines.net</span>
      </div>
    </footer>
  );
};
